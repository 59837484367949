.p-component {
  font-family: $p-font;
  // font-size: 13px;
  font-weight: normal;
}

.p-button {
  background-color: $p-color;
  border: none;
  &:hover,
  &:enabled:hover,
  &.p-fileupload-choose:not(.p-disabled):hover {
    background-color: darken($p-color, 10%);
  }
}
