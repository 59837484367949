/* You can add global styles to this file, and also import other style files */
/* $theme-colors: (
  'primary': #ee6001,
); */

@import 'assets/scss/abstracts/variables';
@import 'assets/scss/theme';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'libs/fe/ui-ng/scss/style.scss';
@import 'assets/scss/primeng/theme.css';
@import 'assets/scss/primeng/primeng-theme';
